import request from '@/utils/request'



export function queryAccount() {
  return request({
    url: '/bank/account',
    method: 'get'
  })
}

export function updateAccount(parameter) {
  return request({
    url: '/bank/account',
    method: 'post',
    data: parameter
  })
}

export function deleteAccount(parameter) {
  return request({
    url: '/bank/account/delete/' + parameter.id,
    method: 'delete',
  })
}

export function queryPayChannel(parameter) {
  return request({
    url: '/payment/channel/config/page',
    method: 'post',
    data: parameter
  })
}

export function addPaymentChannel(parameter) {
  return request({
    url: '/payment/channel/config',
    method: 'post',
    data: parameter
  })
}

export function deletePaymentChannel(parameter) {
  return request({
    url: '/payment/channel/config/delete/'+parameter.id,
    method: 'delete',
    data: parameter
  })
}

//渠道号查询
export function channelAccount(parameter) {
  return request({
    url: '/payment/channel/leshua/config/page',
    method: 'post',
    data: parameter
  })
}

export function addChannelAccount(parameter) {
  return request({
    url: '/payment/channel/leshua/config',
    method: 'post',
    data: parameter
  })
}

export function deleteChannelAccount(parameter) {
  return request({
    url: '/payment/channel/leshua/config/delete/'+parameter.id,
    method: 'delete',
    data: parameter
  })
}
//绑定渠道号
export function handleBindingChannel(parameter) {
  return request({
    url: '/payment/channel/config/bind/'+parameter.id,
    method: 'get'
  })
}